//@ts-nocheck
"use client"
import React, { FC } from "react";
import Script from 'next/script';
import {
    Col,
    Container,
    Row
} from "react-bootstrap";
import Link from 'next/link';
import classes from "./Footer.module.css";
import { FeedbackForm } from "../components/cockpit/FeedbackForm/FeedbackForm";
import Calculate from "../components/modals/Calculate";
import convert from "htmr";
import { useLandingContext } from "../../context/LandingProvider";
import { helpers } from "../../services/_helpers/Helpers";

interface IProps {
    theme: any;
};
const Footer: FC<IProps> = ({ theme }) => {
    return (
        <React.Fragment>
            <footer
                id="footer"
                className={`${classes.footer} py-5`}
                style={{ background: `${theme.mainColor ? theme.mainColor : '#4CADC9'}` }}
            >

                <Container>
                    <Row className="justify-content-between">
                        <Col xs="12" md={4}>
                            <Link href="/src/pages">
                                <img
                                    className={`img-fluid ${classes.mainLogo}`}
                                    src={helpers.constant.cms.storage + theme?.logo?.path}
                                    alt={theme.headerTitle ? theme.headerTitle : null}
                                    title={theme.headerTitle ? theme.headerTitle : null}
                                />
                            </Link>
                            <div className="text w-100">
                                {theme.footerText
                                    ? convert(theme.footerText)
                                    :
                                    <React.Fragment>
                                        <p>{theme.headerTitle ? theme.headerTitle : null} — сайт официального партнера ООО «КТ «Рюкзак
                                            путешествий».</p>
                                    </React.Fragment>
                                }
                                <p>Где купить путевки — Крымский туроператор «Рюкзак путешествий»</p>
                                <p>Нас Вы можете найти по адресу:</p>
                                <p>Россия, Республика Крым, 295000, г. Симферополь, ул. Екатерининская, д.48, 2 этаж.</p>
                                <p>Время работы: Пн- Пт с 10.00-19.00 Сб с 11.00-17.00</p>
                            </div>
                            <a
                                href="https://tourism.gov.ru/operators/show.php?id=101864"
                                target="_blanck" rel="noopener noreferrer">
                                <Row className={`${classes.russiatourismBox} rounded`}>

                                    <Col xs="9">
                                        <p className={classes.bigfont}><strong>Мы в реестре туроператоров</strong></p>
                                        <p className={classes.smfont}>ООО "КТ "РЮКЗАК ПУТЕШЕСТВИЙ"</p>
                                        <p>РТО 017840</p>
                                    </Col>
                                    <Col xs="3" className="px-0">
                                        <img src="/russiatourism.png" />
                                        <p>Ростуризм</p>
                                    </Col>
                                </Row>
                            </a>
                        </Col>
                        <Col xs="12" md={4}>
                            <p>Мы всегда на связи!</p>
                            <p>Звоните нам по любым вопросам</p>
                            <p>с Понедельника по Воскресенье: с 9.00 — 22.00</p>
                            <p><i className={classes.phoneIcon}></i>+7(800) 511-35-15(бесплатно)</p>
                            <p><i className={classes.phoneIcon}></i>+7(495) 266-02-15</p>
                            <p><i className={classes.phoneIcon}></i>+7 978 010 12 60</p>
                            <p><i className={classes.phoneIcon}></i>+7 988 410 10 30</p>
                            <p><i className={classes.phoneIcon}></i>+7 978 550 03 00</p>
                            {theme.email ? <p><i className={classes.emailIcon}></i>{theme.email}</p> : null}
                        </Col>
                        <Col xs="12" md={4} className={classes.alert}>
                            <FeedbackForm />
                        </Col>
                    </Row>
                    <Row className="justify-content-between">
                        <Col xs="12">
                            <p>Отправляя сообщение через любую форму на сайте, Вы соглашаетесь с <Link legacyBehavior href="/policy"><a target="_blank" className="text-white text-decoration-underline"><strong>Политикой конфиденциальности</strong></a></Link> данного сайта.</p>
                        </Col>
                    </Row>
                    {/*<FixedButton />*/}
                </Container>
            </footer>
            <Calculate />
            {theme.jivo && theme.jivo.length != 0 && <Script strategy="afterInteractive" src={`https://code.jivosite.com/script/widget/${theme.jivo}`} />}
        </React.Fragment>
    )
};

export default Footer;

