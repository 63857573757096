//@ts-nocheck
"use client"
import React, { FC, useState, useEffect } from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Col,
    Container,
    Row,
    Button,
    Modal,
    FormGroup,
    FormLabel,
    FormControl,
    Alert,
    Spinner
} from "react-bootstrap";
import MaskedInput from "react-text-mask";
import moment from 'moment';


import InputText from "../forms/inputs/InputText";
import { postApi } from "../../../server/lib/api";

import { cmsApi } from "../../api/cms";
import { formsApi } from "../../api/forms";
import { mailApi } from "../../api/mail";
import AlertInfo from "../ui/alert/AlertInfo";
import Utils from "../../../utils/all";
import { useLandingContext } from '../../../context/LandingProvider';
import { helpers } from "../../../services/_helpers/Helpers";
import { api } from "../../../services/api/Api";

interface IProps { };

const BackCall: FC<IProps> = (props) => {
    const { landing, notify, theme } = useLandingContext();
    const [modal, setModal] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isSend, setIsSend] = useState<boolean>(false);
    const [alertInfo, setAlertInfo] = useState<string>("");

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required("Заполните это поле"),
        email: Yup.string()
            .email("Введен недействительный адрес электронной почты")
            .required("Заполните это поле"),
        phone: Yup.string()
            .required("Заполните это поле"),
    });

    const handleSubmit = async (event, values) => {
        console.log("values", values);
        event.preventDefault();
        setDisabled(true);
        setIsSend(true);
        await new Promise((r) => setTimeout(r, 500));

        const alertText = notify.formCall && values.firstName ? Utils.createStringFromTemplate(notify.formCall, { fName: values.firstName }) : "";

        const query = {
            publish: false,
            name: values.firstName,
            phone: values.phone,
            site: {
                _id: landing._id,
                link: "sites",
                display: landing.title
            }
        };
        const result = await api.cms.save('backcall', query);

        if (result) {
            const html = `
                                            <p><strong>Дата: </strong>${helpers.date.today()}</p>
                                            <p><strong>Имя: </strong>${values.firstName}</p>
                                            <p><strong>Телефон: </strong>${values.phone}</p>
                                            <p><strong>Сайт: </strong>${landing.domain}</p>
                                        `;

            const mail = {
                to: [theme.email, 'testcrm@bag-travels.ru'],
                subject: `Обратный звонок - ${landing.domain}`,
                html: html,
            };
            await api.mail.send(landing.domain, mail);
            setAlertInfo(alertText);
            setIsSend(false);
            ym(369479095, 'reachGoal', 'goal_name');
            setTimeout(() => {
                setModal(false)
            }, 12000);
        }
    };

    return (
        <React.Fragment>
            <Button onClick={() => setModal(true)}>ОБРАТНЫЙ ЗВОНОК</Button>
            <Container>
                <Modal show={modal} onHide={() => setModal(!modal)} centered>
                    <Modal.Body>
                        <h3 className="text-center">Обратный звонок</h3>
                        <div style={{ padding: 16, margin: 'auto', maxWidth: 600 }}>
                            <Formik
                                initialValues={{
                                    alert: false,
                                    firstName: "",
                                    phone: "+7"
                                }}
                                validationSchema={validationSchema}
                                onSubmit={() => { }}
                            >
                                {({ values, errors, handleChange, handleBlur, touched, isSubmitting }) => (
                                    <Form onSubmit={(event) => handleSubmit(event, values)}>
                                        <FormGroup className="mb-3 required">
                                            <FormLabel
                                                className="text-right text-dark control-label">
                                                Ваше Имя
                                            </FormLabel>
                                            <FormControl
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required
                                                size="sm" name="firstName" type="text"
                                                value={values.firstName}
                                                isValid={touched.firstName && !errors.firstName}
                                                isInvalid={!!errors.firstName}
                                            />
                                            {touched.firstName && errors.firstName && (
                                                <div className="error-message">{errors.firstName}</div>
                                            )}
                                        </FormGroup>

                                        <FormGroup className="mb-3 required">
                                            <FormLabel
                                                className="text-right text-dark control-label">
                                                Ваш Телефон
                                            </FormLabel>
                                            <Field
                                                name="phone"
                                                render={({ field }) => (
                                                    <MaskedInput
                                                        {...field}
                                                        required
                                                        mask={helpers.form.phoneMask()}
                                                        id="phone"
                                                        placeholder="Введите номер"
                                                        type="text"
                                                        value={values.phone}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="form-control form-control-sm"
                                                        isValid={touched.phone && !errors.phone}
                                                        isInvalid={!!errors.phone}
                                                    />
                                                )}
                                            />

                                            {touched.phone && errors.phone ? (
                                                <div className="error-message">{errors.phone}</div>
                                            ) : null}
                                        </FormGroup>
                                        {alertInfo &&
                                            <Row>
                                                <Col xs={12}>
                                                    <AlertInfo
                                                        show
                                                        hide
                                                        type={notify.formCallColor ? notify.formCallColor : "success"}
                                                        hideTime={21000}
                                                    >
                                                        {alertInfo}
                                                    </AlertInfo>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <Col>
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    disabled={disabled}
                                                >
                                                    {isSend &&
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    }
                                                    Отправить
                                                </Button>
                                            </Col>

                                            <Col>
                                                <Button
                                                    variant="danger"
                                                    onClick={() => setModal(false)}
                                                >
                                                    Закрыть
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                            {/* 
                            <form onSubmit={() => onSubmit}>
                                <Container>
                                    <Row>
                                    </Row>


                                </Container>
                            </form> */}
                        </div>
                    </Modal.Body>
                </Modal>
            </Container>
        </React.Fragment >
    );
};

export default BackCall;


//     this.state = {
//         sending: false,
//         alert: false,
//         firstName: "",
//         ,
//     };

// }

